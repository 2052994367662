
export default [

  {
    id: "carlo-capretta",
    socials: [
      {
        id: "instagram",
        url: "https://www.instagram.com/carlo_capretta/",
      },
    ],
  },

  {
    id: "eugenio-felicioni",
    socials: [
    ]
  }

]

import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { find } from "lodash"
import React from "react"
import Layout from "../components/Layout"
import authors from "../sources/authors"

export default injectIntl(
  ({
    data: {
      authorYaml: { id },
      allMarkdownRemark: { edges: postNodes },
    },
    intl,
  }) => (
      <Layout hideHamburger={true}>
        <div>
          <h2>{intl.formatMessage({ id: `${id}:label` })}</h2>
          <br />
          {find(authors, { id: id }).socials.map(social => {
            return (
              <p>
                <a
                  href={`${social.url}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <b>
                    <span>
                      {"@"}
                      {intl.formatMessage({ id: `${social.id}:label` })}
                    </span>
                  </b>
                </a>
              </p>
            )
          })}
          <p>
            <em>
              <formattedHTMLMessage id={`${id}-bio:label`} />
            </em>
          </p>
        </div>
        <hr />
        <p>{`${intl.formatMessage({ id: "postedBy:label" })} ${intl.formatMessage(
          { id: `${id}:label` }
        )}: `}</p>
        {postNodes.map(({ node: post }, idx) => (
          <div key={post.id}>
            <a href={post.fields.slug}>{post.frontmatter.title}</a>
          </div>
        ))}
      </Layout>
    )
)

export const pageQuery = graphql`
  query PostsByAuthorId($authorId: String!) {
    allMarkdownRemark(filter: { fields: { authorId: { eq: $authorId } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            author {
              id
            }
          }
          fields {
            authorId
            slug
          }
        }
      }
    }
    authorYaml(id: { eq: $authorId }) {
      id
    }
  }
`
